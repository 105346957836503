const ChMain        = () => import('@/layouts/Main.vue') 
//const ChDasboard    = () => import('@/pages/Dashboard.vue')


export default {
menu : [
    { 'href': '/dashboard',  'scope':'Generic', 'right': 'index', 'title': 'Tableau de bord', 'icon': 'mdi-view-dashboard' },
    { 'href': '/quotations',    'scope':'Generic', 'right': 'index', 'title': 'Devis', 'icon': 'mdi-invoice-text-edit-outline' },
    { divider: true },
    { 'header': 'Paramètres',    'scope':'Setting',   'right': 'update', 'title': 'Paramètres', 'icon': 'mdi-cogs', items: [
        { 'href': '/states',     'scope':'State',     'right': 'store', 'title': 'Gestion états', 'icon': 'mdi-tag-multiple' },
        { 'href': '/types',      'scope':'Type',      'right': 'store', 'title': 'Gestion des types', 'icon': 'mdi-format-list-bulleted-type' },
    ] },
    { divider: true },
    { 'header': 'Systeme', 'role': ['ADMIN_USER', 'SUPER_USER' ] },
    { 'href': '/users', 'title': 'Utilisateurs', 'icon': 'mdi-account', 'scope':'Type',      'right': 'store', 'role': ['ADMIN_USER', 'SUPER_USER' ] },
    { 'href': '/companies', 'title': 'Sociétés', 'icon': 'mdi-domain', 'role': ['ADMIN_USER', 'SUPER_USER' ] },
    //{ 'href': '/importdata',  'title': 'Import / synchro',  'icon': 'mdi-file-upload', 'role': ['ADMIN_USER', 'SUPER_USER'] },
    //{ 'href': '/jobs',  'title': 'Taches de fond', 'icon': 'mdi-progress-wrench', 'role': ['ADMIN_USER', 'SUPER_USER'] },
],
routes : [
    { name:'login', path: '/login',   component: require('./layouts/Login.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'setpass', path: '/auth/set-password/:token',   component: require('./layouts/Password.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'signin', path: '/signin/',   component: require('./layouts/SignIn.vue').default, 
        meta: {
            public: true,  // Allow access to even if not logged in
            onlyWhenLoggedOut: true
        }
    },
    { name:'logout', path: '/logout',   component: require('./layouts/Logout.vue').default },
    { name: '404', path: '*', component: require('./layouts/404.vue').default },
    { name: '401', path: '/401', component: require('./layouts/401.vue').default },
    { path: '/', component: ChMain,
        children: [
            { name: 'home', path: '/', redirect: '/dashboard' },
            { name: 'dashboard',    path: '/dashboard',               component: require('./pages/Dashboard.vue').default },
            
            { name: 'companies',      path: '/companies',         component: require('./pages/Companies.vue').default },
            { name: 'companies-edit', path: '/companies/:cpy_id', component: require('./pages/Companies.vue').default },

            { name: 'quotations',     path: '/quotations',        component: require('./pages/Quotations.vue').default },
            { name: 'quotations-edit',path: '/quotations/:quo_uid',component: require('./pages/Quotations.vue').default },

            { name: 'users',        path: '/users',               component: require('./pages/Users.vue').default },
            { name: 'useredit',     path: '/users/:id',           component: require('./pages/Users.vue').default },

            { name: 'states',         path: '/states',            component: require('./pages/States.vue').default },
            { name: 'statesedit',     path: '/states/:sta_id',    component: require('./components/states/StatesForm.vue').default },
            { name: 'types',          path: '/types',             component: require('./pages/Types.vue').default },
            { name: 'typesedit',      path: '/types/:typ_id',     component: require('./pages/Types.vue').default },

            { name: 'forbidden',    path: '/forbidden', component: require('./layouts/403.vue').default },
            
        ]
    }
]


}