<template>
<v-container fluid class="pa-0">
    <PageHeader :contextStyle="contextStyle">
      <!-- <template #right><v-btn small><v-icon small color="success">mdi-file-excel</v-icon></v-btn></template> -->
    </PageHeader>

    <v-card v-if="( $route.params.cpy_id !== undefined && parseInt( $route.params.cpy_id ) >= 0 )" fluid class="ma-2 pa-3 elevation-0 mikro-v-card">
    <v-tabs v-model="activeTab" grow light show-arrows="mobile" slider-size="1" class="mt-4" style="border-bottom-left-radius: 40px !important;">
        <v-tabs-slider color="green"></v-tabs-slider>
        <v-tab v-for="item in tabItems" :key="item.title" :disabled="item.disabled"
            class="pl-3 pr-1 pb-4 rounded-lg" style="border-bottom-left-radius: 20px !important; background-color:white;">
            <span>
                <v-icon class="mr-1" :color="(activeTab.event == 0) ? 'red'  : 'grey'">{{item.icon}}</v-icon>
                <span class="hidden-sm-and-down">
                    {{ item.title }}
                </span>
            </span>
        </v-tab>
    </v-tabs>
    <CompaniesForm v-if="activeTab === 0 && ( $route.params.cpy_id !== undefined && parseInt( $route.params.cpy_id ) >= 0 )"/>
    <CompaniesQuotation v-if="activeTab === 1 && ( $route.params.cpy_id !== undefined && parseInt( $route.params.cpy_id ) >= 0 )" :cpy_id="$route.params.cpy_id"/>
    <DocumentTab v-if="activeTab === 2" :routeState="routeState" :doc_xxx_id.sync="item.cpy_id" :table_id="'cpy'" defaultType="DOC01" @linkDoc="linkedDocument"></DocumentTab>
    <UsersList v-if="activeTab === 3 && ( $route.params.cpy_id !== undefined && parseInt( $route.params.cpy_id ) >= 0 )" class="ml-6 mr-6" :cpy_id="$route.params.cpy_id"/>
    </v-card>

    <v-card v-if="( $route.params.cpy_id == undefined )" class="ma-4 rounded-lg">
        <CompaniesList />
    </v-card>
</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import CompaniesList from '@/components/companies/CompaniesList'
import CompaniesForm from '@/components/companies/CompaniesForm'
import CompaniesQuotation from '@/components/companies/CompaniesQuotation'
import UsersList from '@/components/users/UsersList'
import DocumentTab from '@/components/documents/DocumentList.vue'
//import { filterUpdateService } from '@/rxjsServices';
import { mapWritableState, mapActions } from 'pinia'
import { useCompanyStore } from '@/stores/company'

import { bus } from '@/plugins/bus'

export default {
    name: 'companies-page',
    props: ['routeState', 'contextStyle'],
    components: {
        PageHeader,
        CompaniesList,
        CompaniesForm,
        CompaniesQuotation,
        UsersList,
        DocumentTab
    },
    watch: {
        $route () {
            if( !this.$route.params.tabid ){
                this.activeTab = 0 // todoo
            }
        }
    },
    data: function () {
        return {
            loading: { getXls: false, getTasks: false },         // Enregistrement en cours...
            items: [],
            enableAdd: true,
            activeTab: -1, 
            showTab: true,
            tabItems: [
                { title: 'Fiche',               disabled: false, icon: 'mdi-file-document-multiple-outline'},
                { title: 'Devis',               disabled: false, icon: 'mdi-clock-time-eight-outline'},
                { title: 'Logo / documents',    disabled: false, icon: 'mdi-file-document-edit-outline'},
                { title: 'Utilisateurs',        disabled: false, icon: 'mdi-folder-multiple-outline'},
            ],
        }
    },
    computed: {
        ...mapWritableState(useCompanyStore, {item: 'item' }),
    },
    created(){},
    mounted: function (){
        //this.search = cacheGetters.getSearchBarItem('tasktypes') || ''
        //this.getItems()
        bus.$on('document:link', (obj) => {
            if( obj.doc_typ_id == 'DOC04' ){
                this.item.cpy_doc_id = obj.doc_id
                this.updateItem()
            }
        })
        bus.$on('document:save', (obj) => {
            if( obj.doc_typ_id == 'DOC04' ){
                this.item.cpy_doc_id = obj.doc_id
                this.updateItem()
            }
        })
        if( this.$route.params.tabid && parseInt( this.$route.params.tabid ) > 0 ){
            this.activeTab = parseInt( this.$route.params.tabid )
        }
    },
    methods: {
        ...mapActions(useCompanyStore, {updateItem: 'updateItem'}),
        async getItems(){
            this.items = []
        },
        linkedDocument(doc_id){
            this.item.cpy_doc_id = doc_id
        },
    },
    beforeDestroy() {
        //this.subTask.unsubscribe()
        bus.$off('document:link')
    },
}
</script>