<template>
<v-container>
    <v-row>
        <v-col cols="6" md="6">
            <img v-if="(planId == 'CPY01')" width="210" :src="logo.plan1" style="cursor:pointer;" class="ml-1" />
            <img v-if="(planId == 'CPY02')" width="210" :src="logo.plan2" style="cursor:pointer;" class="ml-1" />
        </v-col>
        <v-col cols="6" md="6" class="text-right">
            <span v-if="(planId == 'CPY01')" style="color:white;" class="subtitle-1">Adhérent</span><br />
            <span v-if="(planId == 'CPY02')" style="color:white;" class="subtitle-1">Détenteur</span><br />
        </v-col>
    </v-row>
    <v-container v-if="(planId == 'CPY01')">
        <span style="color:white;" class="subtitle-1">Fonctions :</span><br />
        <span style="color:white;" class="subtitle-2">
            <ul>
                <li>CCGV / info</li>
            </ul>
        </span>
    </v-container>
    <v-container v-if="(planId == 'CPY02')">
        <span style="color:white;" class="subtitle-1">Fonctions :</span><br />
        <span style="color:white;" class="subtitle-2">
            <ul>
                <li>CCGV / info</li>
            </ul>
    </span>
    </v-container>
</v-container>
</template>

<script>
export default {
    name: 'sign-plan-component',
    props: ['planId'],
    components: { },
    data () {
        return {
            logo: {
                plan2: require('@/assets/logo_signin.svg'),
                plan3: require('@/assets/logo_' + process.env.VUE_APP_NAME + '.svg'),
                plan4: require('@/assets/logo_' + process.env.VUE_APP_NAME + '.svg'),
                plan1: require('@/assets/logo_signin.svg'),
            },
        }
    },
    mounted:function (){

    }
}
</script>