<template>
    <v-container fluid class="pa-0">
    <!-- Formulaire -->
    <v-card fluid class="ma-2 pa-3 elevation-0 mikro-v-card">
        <v-card-title >
            Devis {{ (item.quo_ref) ? item.quo_ref : '' }}
            <v-spacer></v-spacer>
            <v-chip outlined color="secondary">
                    <v-icon left>mdi-information-outline</v-icon>Modification: {{ $date(item.equ_updated_at).format('DD MMMM YYYY') }}
            </v-chip>
        </v-card-title>
        <v-form ref="form" v-model="validForm" lazy-validation>
        <v-row no-gutters>
            <v-col cols="3" class="pr-2">
                Image
            </v-col>
            <v-col cols="9" class="pr-2">
                <v-row no-gutters>
                    <v-col cols="12">Ref client</v-col>
                    <v-col cols="12">Tiers</v-col>
                    <v-col cols="12">Autre</v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="8" class="pr-2">
                <v-row no-gutters>
                    <v-col cols="6">Date</v-col>
                    <v-col cols="6">Montant HT</v-col>
                    <v-col cols="6">Fin de validité</v-col>
                    <v-col cols="6">Montant TTC</v-col>
                    <v-col cols="6">Date intervention</v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="8" class="pr-2">
                <v-text-field :rules="nameRules" outlined dense class="rounded-lg" label="Titre" v-model="item.quo_name" ></v-text-field>
            </v-col>
            <v-col cols="1" md="1" class="pr-2">
            </v-col>
            <v-col cols="12" md="4" class="pr-2">
                
            </v-col>
        </v-row>
        </v-form>
        <v-card-actions>
            <v-btn class="rounded-xl" large color="red" v-if="item.uid" @click="deleteItem(item)"><v-icon title="Supprimer">mdi-delete</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn class="rounded-xl" large color="success" :loading="loading.fetchData" @click.native="saveItem()" :disabled="(loading.fetchData || !validForm)">Enregistrer</v-btn>
            <v-btn class="rounded-xl" large color="primmary" href="/#/quotations/">Retour</v-btn>
        </v-card-actions>
    </v-card>
    </v-container>
</template>
    
<script>
//import AutocompleteMulti from '@/components/ui/AutocompleteMulti'

import { mapWritableState, mapActions, mapState } from 'pinia'
import { useQuotationStore } from '@/stores/quotation'
import { useCompanyStore } from '@/stores/company'

export default {
    name: 'quotations-edit',
    components: { }, //AutocompleteMulti,
    computed: {
        ...mapWritableState(useQuotationStore, {item: 'item' }),
        ...mapState(useCompanyStore, { company: 'item' }),
    },
    watch: { },
    data: function () {
        return {
            loading: { fetchData: true },
            listEntities: [], listAgencies: [],
            search: null, searchAddress: null, searchBoxClosed: true, addressTab: [], timeOutHandle: 'off',
            validForm: true,
            nameRules: [ v => !!v || 'Le champ doit être renseigné', v => (v && v.length <= 100) || 'Doit être inférieur à 100 charactères', ],
            emailRules: [ v => !!v || 'E-mail requis', v => /.+@.+\..+/.test(v) || 'E-mail doit être valide', ],
        }
    },
    async mounted () {
        //await this.fetchItem( this.$route.params.quo_id, '?ent_sta_id=GEN01' )
        this.loading.fetchData = false
    },
    methods: {
        ...mapActions(useQuotationStore, {fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem'}),
        async saveItem () {
            await this.$refs.form.validate()
            if( this.validForm ){
                // Enregistrement du formulaire
                this.loading.fetchData = true
                if( parseInt( this.$route.params.equ_id ) > 0 ){
                    this.updateItem()
                } else {
                    this.storeItem()
                }
                this.$router.push({ path: '/quotations' })
            }
        },
    }
}
</script>