import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import francais from 'vuetify/es5/locale/fr'

const opts = {
    icons: {
        iconfont: 'mdiSvg',
        values: {
            customIconSvg: 'mdi-menu',
            customIconSvgPath: 'M14.989,9.491L6.071,0.537C5.78,0.246,5.308,0.244,5.017,0.535c-0.294,0.29-0.294,0.763-0.003,1.054l8.394,8.428L5.014,18.41c-0.291,0.291-0.291,0.763,0,1.054c0.146,0.146,0.335,0.218,0.527,0.218c0.19,0,0.382-0.073,0.527-0.218l8.918-8.919C15.277,10.254,15.277,9.784,14.989,9.491z',
        }
    },
    theme: {
        //dark: false,
        themes: {
            light: {
                primary:   '#06D6A0', // #052F5F
                secondary: '#052F5F', // #06D6A0
                accent:    '#c3d343', 
                error:     '#FF5252',
                info:      '#2196F3',
                success:   '#c3d340',
                warning:   '#FFC107',
                barrinfo:  '#E5E5E5',
                barrtextinfo:  '#0A2A35',
            },
            dark: {
                primary:   '#052F5F', // #052F5F
                secondary: '#06D6A0', // #06D6A0
                accent:    '#c3d343', 
                error:     '#FF5252',
                info:      '#2196F3',
                success:   '#c3d340',
                warning:   '#FFC107',
                barrinfo:  '#E5E5E5',
                barrtextinfo:  '#0A2A35',
            }
        }
    },
    lang: {
        locales: { francais },
        current: 'francais',
    },
}

export default new Vuetify( opts );
