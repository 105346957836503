<template>
<v-container fluid class="pa-0">
    <fieldset fluid class="mt-5 ma-2 pa-0 elevation-0 rounded-xxl micorise-v-card"> <!-- style="background-color:#E0F2F1" -->
        <legend style="margin-left: 1em; padding: 0.2em 0.8em ">Devis / commandes</legend>
        <v-card fluid class="ma-2 pa-3 elevation-0 rounded-xxl">
            <v-data-table v-bind:headers="headers" :items="quotationList" 
            items-per-page-text="Lignes par page" :hide-default-header="false" :items-per-page-options="pagination.rowsPerItem"
            :loading="loading.fetchData" loading-text="Chargement... Veuillez patienter">
            
                <template v-slot:[`item.ref`]="{ item }">
                <strong flat color="blue" class="ma-0 subheading text-uppercase blue--text">{{ item.ref }}</strong>
                </template>
                <template v-slot:[`item.date`]="{ item }">
                    <v-chip label outlined>{{ (item.date) ? $date(item.date * 1000).format('DD/MM/YYYY') : '---/---/--------' }}</v-chip>
                </template>
                <template v-slot:[`item.total_ht`]="{ item }">{{ parseFloat(item.total_ht).toFixed(2) }}</template>
                <template v-slot:[`item.total_tva`]="{ item }">{{ parseFloat(item.total_tva).toFixed(2) }}</template>
                <template v-slot:[`item.total_ttc`]="{ item }">{{ parseFloat(item.total_ttc).toFixed(2) }}</template>
                <template v-slot:[`item.last_main_doc`]="{ item }">
                    <v-btn outlined small color="green" class="ml-2 elevation-1" @click="downloadInvoice(item.last_main_doc)">
                        <v-icon left class="mr-2">mdi-file-pdf-box</v-icon>
                    </v-btn>
                </template>
                <template slot="pageText" slot-scope="{ pageStart, pageStop }">
                De {{ pageStart }} à {{ pageStop }}
                </template>
            </v-data-table>
        </v-card>
    </fieldset>

</v-container>
</template>
    
<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useCompanyStore } from '@/stores/company'
import { useQuotationStore } from '@/stores/quotation'

import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'
import { useUserStore } from '@/stores/user'

export default {
    name: 'companies-edit',
    props: [ ],
    components: { },
    data: function () {
        return {
            loading: { fetchData: true, saveProgress: false },
            search: null, searchAddress: null, searchBoxClosed: true, addressTab: [], timeOutHandle: 'off',
            quotations: [],
            quotationList: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Ref',              align: 'left', value: 'ref'},
                { text: 'Total ht',         align: 'left', value: 'total_ht'},
                { text: 'Total tva',        align: 'left', value: 'total_tva'},
                { text: 'Total ttc',        align: 'left', value: 'total_ttc'},
                { text: 'Date',             align: 'left',   value: 'date'},
                { text: 'Télécharger',       align: 'left',   value: 'last_main_doc'},
            ],
        }
    },
    computed: {
        ...mapWritableState(useCompanyStore, {item: 'item' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
        ...mapState(useQuotationStore, {quotationList: 'itemList' }),
    },
    async mounted (){
        await this.fetchItem( this.$route.params.cpy_id, '?ent_sta_id=GEN01' )
        //await this.fetchQuotation()
    },
    methods: {
        ...mapActions(useCompanyStore, {fetchItem: 'fetchItem'}),
        ...mapActions(useQuotationStore, {fetchQuotation: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem', destroyItem: 'destroyItem'}),
        ...mapActions(useUserStore,      { fetchMe: 'fetchMe'}),
        downloadInvoice(id){
            return id
        }

    }
}
</script>