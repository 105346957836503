<template>
    <v-container fluid class="pa-0">
        <PageHeader :contextStyle="contextStyle">
            <!-- <template #right><v-btn small rounded color="primary" href="#/customers/0">Ajouter un client</v-btn></template> -->
        </PageHeader>
    
        <QuotationForm v-if="( ($route.params.quo_uid == 0) || ($route.params.quo_uid !== undefined && $route.params.quo_uid.length > 8) )"/>
        <v-card v-else class="ma-4 rounded-lg">
            <QuotationList />
        </v-card>
    
    </v-container>
    </template>
    
    <script>
    import PageHeader from '@/components/ui/PageHeader'
    import QuotationList from '@/components/quotations/QuotationList'
    import QuotationForm from '@/components/quotations/QuotationForm'
    
    //import { filterUpdateService } from '@/rxjsServices';
    
    export default {
        name: 'quotations-page',
        props: ['contextStyle'],
        components: {
            PageHeader,
            QuotationList, QuotationForm
        },
        data: function () {
            return {
    
            }
        },
        computed: { },
        created(){},
        mounted: function (){
    
        },
        methods: {
    
        },
        beforeDestroy() {
    
        },
    }
    </script>