<template>
<v-container fluid>
<v-row>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardClass" tile flat>
            <v-toolbar flat color="primary" prominent height="50px">
                <v-toolbar-title><v-icon>mdi-chart-bar</v-icon>Devis validés</v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- <v-chip outlined small label color="green" title="Dernière modification">{{locDateFormat.toLocale(lastUpdatedAt, { year: 'numeric', month: 'long', day: 'numeric' })}}</v-chip> -->
            </v-toolbar>
            <v-card-text>
                <VueApexCharts v-if="chartView.area" width="100%" height=440 type="line" :options="chart1Options" :series="series1"></VueApexCharts>
                <v-alert v-else color="info" class="mt-2" icon="mdi-alert">Aucune donnée</v-alert>
            </v-card-text>
        </v-card>
    </v-col>
    <v-col cols="12" md="6">
        <v-card :class="uiParams.cardClass" tile flat >
        <v-toolbar flat color="primary" prominent height="50px">
            <v-toolbar-title><v-icon>mdi-chart-bar</v-icon>Devis en cours</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-chip outlined small label color="green" title="Dernière modification">{{locDateFormat.toLocale(lastUpdatedAt, { year: 'numeric', month: 'long', day: 'numeric' })}}</v-chip> -->
        </v-toolbar>
        <v-card-text class="pa-0 rounded-xxl pb-5">
            <v-list dense class="pb-3" two-line v-if="quotations.length">
            <template>
                <v-list-item v-for="(item, index) in quotations" :key="index" class="pb-0">
                    <v-list-item-content>
                        <v-list-item-title :style="'color:' + item.quo_sta_id + ';'">
                            <v-icon>mdi-invoice-list-outline</v-icon> <span class="caption grey--text">{{ item.quo_ext_id }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="caption font-italic">{{ item.cpy_name + ' - ' + item.cpy_address }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="mt-1 mb-1">
                        <span class="caption">{{ item.quo_updated_at }}</span>
                        <v-chip small label outlined :color="stateListById[item.quo_sta_id].sta_color">
                            {{ stateListById[item.quo_sta_id].sta_label }}
                        </v-chip>
                    </v-list-item-action>
                </v-list-item>
            </template>
            </v-list>
            <v-alert v-else color="info" class="ma-2" icon="mdi-alert">Aucune donnée</v-alert>
        </v-card-text>
        </v-card>
    </v-col>
    <v-col cols="12" md="6"></v-col>
    <v-col cols="12" md="6">
        
    </v-col>
</v-row>

</v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import apexLocale  from '@/plugins/apexchart.js'

import { mapState } from 'pinia'
import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'

export default {
    name: 'dashboard-page',
    props: [],
    components: { VueApexCharts },
    computed: {
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
    },
    data() {
        return {
            uiParams: {
                cardClass: 'mb-4 card-thumbnail',
                cardToolbarColor: '#052F5F'
            },
            chartView: { area: false },
            chart1Options: {
                chart: {
                    id: 'example1',
                    toolbar: { show: true, tools: { download: true, selection: true, zoom: true, zoomin: true, zoomout: true, pan: true } },
                    locales: apexLocale.locales, defaultLocale: "fr"
                },
                labels: [],
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                }
            },
            series1: [ {name: 'series-1', data: [30, 40, 45, 50, 49, 60, 70, 91, 99] }],
            quotations: []
        }
    },
    created(){
       
    },
    async mounted(){
        this.quotations = [
            {"quo_id":17550,"quo_sta_id":'GEN01',"quo_ext_id":10, cpy_name: 'JPG', cpy_address: '93 Rue du Lac, LABEGE', "quo_updated_at":"2024-10-25"},
            {"quo_id":17550,"quo_sta_id":'GEN01',"quo_ext_id":10, cpy_name: 'JPG', cpy_address: '93 Rue du Lac, LABEGE', "quo_updated_at":"2024-10-26"},
            {"quo_id":17550,"quo_sta_id":'GEN01',"quo_ext_id":10, cpy_name: 'JPG', cpy_address: '93 Rue du Lac, LABEGE', "quo_updated_at":"2024-10-27"},
        ]
        this.chartView.area = true

    },
    methods:{

    },
}
</script>

<style>
    .small {
        max-width: 600px;
    }
    .card-thumbnail {
        border-color: #068598 !important;
        border: solid 1px #068598 !important;
    }
    .theme--light.v-chip:hover::before {
        opacity: 0;
    }
    .v-input--selection-controls__input {
        padding-left: 1em;
    }
</style>