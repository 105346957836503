import ApiService from '@/services/api'
import { defineStore } from 'pinia'
//import { taskUpdateService } from '@/rxjsServices';

const ENDPOINT = '/documents/'
const ITEM_ID  = 'doc_id'

export const useDocumentStore = defineStore('documents', {
  // convert to a function
  state: () => ({
    item: { doc_customfields: {}  },
    itemList: []
  }),
  getters: {

  },
  actions: {
    fetchItem (id, options) {
        return new Promise( (resolve, reject) => {
            options = (options) ? '/' + options : ''
            if( id && id.length > 8 ){
                ApiService.get(ENDPOINT + id + options).then( (response) => {
                    if( !response.data.data.doc_customfields || Object.prototype.toString.call( response.data.data.doc_customfields ) === '[object Array]' ) {
                        response.data.data.doc_customfields = {}
                    }
                    this.SET_ITEM(response.data.data)
                    resolve(this.item)
                }).catch( (error) => { 
                    reject(error)
                })
            } else {
                this.RESET_ITEM()
                resolve(this.item)
            }
        })
    },
    fetchItems (options) {
        return new Promise( (resolve, reject) => {
            options = (options) ? options : ''
            ApiService.get( ENDPOINT + options ).then( (response) => {
                this.metaTask = response.data.meta
                if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                    this.SET_ITEMS(response.data.data)
                }
                resolve('resolved')
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    storeItem () {
        return new Promise( (resolve, reject) => {
            var formFile = new FormData()
            for (var prop in this.item) {
                if( prop !== 'obj_file' ){
                    formFile.append(prop, this.item[prop])
                }
            }
            if( this.item.obj_file ) {
                formFile.append('docfile', this.item.obj_file, this.item.obj_file.name)
            }
            ApiService.post( ENDPOINT, formFile ).then( (response) => {
                this.SET_ITEM(response.data.data)
                resolve(this.item)
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    updateItem () {
        return new Promise( (resolve, reject) => {
            ApiService.put( ENDPOINT + this.item[ITEM_ID], this.item ).then( (response) => {
                this.SET_ITEM(response.data)
                resolve(this.item)
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    destroyItem (id) {
        return new Promise( (resolve, reject) => {
            this.item[ITEM_ID] = (id) ? id : this.item[ITEM_ID]
            ApiService.delete(ENDPOINT + this.item[ITEM_ID]).then( () => {
                this.RESET_ITEM()
                resolve('resolved')
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    downloadItem(id, fileName, options){
        return new Promise( (resolve, reject) => {
            options = (options)  ? options : ''
            fileName= (fileName) ? fileName : 'rapport'
            ApiService.customRequest({ method: 'get', url: 'documents/' + id + '/download' + options, responseType: 'blob' } ).then( (response) => {
                let filename      = 'd.pdf'
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(response.headers['content-disposition']);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
                if( response.status == 204 ){
                    resolve({blob: null, filename: '', contentType: '' })
                }
                resolve({blob: response.data, filename: filename, contentType: response.headers['content-disposition'] })
            }).catch( (error) => { 
                reject(error)
            })
        })
    },
    //***************************MUTATIONS ***************************************/
    SET_ITEM (item) {
      this.item = item
    },
    SET_ITEMS (itemList) {
      this.itemList = itemList
    },
    RESET_ITEM () {
        this.item = {
            doc_id: 0, doc_sta_id : 'GEN01', doc_xxx_id  : '', doc_table_id: 'act',
            doc_typ_id: '', doc_pinned: 0, doc_flags: 0, doc_mimetype: '', doc_filename: null, 
            doc_size : 0, doc_name : '', doc_description: '', doc_usr_id_created: 0,
            doc_created_at : '', doc_updated_at: 0, obj_file : null
        }
    }
  }
})
