<template>
    <v-data-table v-bind:headers="headers" :items="itemList" item-key="id" :search="search"
        items-per-page-text="Lignes par page" hide-default-header
        :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0">
                <v-col cols="6" md="6" class="pa-1">
                    <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                                hide-details v-model="search" class="pa-2 rounded-xl">
                    </v-text-field>
                </v-col>
                <v-col cols="1" md="1" class="">
                    <v-btn small rounded :color="(itemState == 'GEN01') ? 'grey' : 'blue'" 
                        @click="(itemState === 'GEN01') ? itemState='GEN01,GEN02' : itemState='GEN01'; fetchList();">Voir devis archivés</v-btn>
                </v-col>
                <v-col cols="5" md="5" class="text-right pa-1">
                    <v-btn small rounded color="primary" href="/#/quotations/0">Nouveau devis</v-btn>
                </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.equ_name`]="{ item }"><v-chip class="ma-2" color="secondary">{{ item.equ_name }}</v-chip><v-icon>mdi-arrow-right</v-icon></template>
        <template v-slot:[`item.equ_id`]="{ item }">
            <v-btn v-if="$root.$permissions.Equipment.update" rounded :href="'/#/equipments/' + item.equ_id + ''" color="orange" elevation="0" dark>
                <v-icon left  v-if="$root.$permissions.Equipment.update" >{{item.equ_id}} mdi-pencil</v-icon> Fiche
            </v-btn>
        </template>
        <template v-slot:no-data>
        <v-alert :value="( !itemList.lenght )" color="error" icon="mdi-alert">
            Aucun evenement
        </v-alert>
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useQuotationStore } from '@/stores/quotation'

export default {
    name: 'quotations-list',
    computed: {
        ...mapState(useQuotationStore, {itemList: 'itemList' })
    },
    data: function () {
        return {
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Nom',    value: 'quo_name', align: 'left'},
                { text: 'Série',  value: 'quo_serial', align: 'left'},
                { text: 'Action', value: 'quo_id', align: 'right'},
            ],
            itemState: 'GEN01'
        }
    },
    async mounted () {
        //this.fetchList()
    },
    methods: {
        ...mapActions(useQuotationStore, {fetchItems: 'fetchItems'}),
        async fetchList(){
            await this.fetchItems('?per_page=false')    
        }
    },
}
</script>