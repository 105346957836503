<template>
    <router-view ></router-view>
</template>
<script>

export default {
  name: 'App',
  data: () => ({
    loginState : ''
  }),
  mounted() {
    this.$root.$style = {
      cardFormClass: 'ma-2 pa-3 elevation-0 mikro-v-card',
      formTextFieldOutlined: true,
      formTextFieldDense: true,
      formTextFieldClass:''
    }
  },
  components: {

  }
}
</script>

<style lang="scss">
@import './assets/leaflet.css';
@import './styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Workbench&display=swap');

$body-font-family: 'IBM Plex Sans';
$title-font: 'IBM Plex Sans';
.v-application {
    font-family: $body-font-family, sans-serif !important;
    color: #73726a !important;
    font-style: normal;
    .title {
       font-family: $title-font, sans-serif !important;
       font-weight: 100;
       color: #73726a !important;
    }
}

</style>