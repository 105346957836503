<template>
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :hide-default-header="false" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:top>
            <v-row class="rounded-lg ma-0 pa-0">
                <v-col cols="6" md="6" class="pa-1">
                    <v-text-field append-icon="mdi-magnify" label="Recherche" outlined dense clearable single-line
                                hide-details v-model="search" class="pa-2">
                    </v-text-field>
                </v-col>
                <v-col cols="6" md="6" class="text-right pa-2">
                    <v-btn color="success" depressed class="white--text" to="/companies/0" v-if="$root.$permissions?.Company?.destroy">
                        <v-icon left dark>mdi-pencil-plus-outline</v-icon>
                        <span class="hidden-sm-and-down">Créer</span>
                    </v-btn>
                </v-col>
            </v-row>
        </template>

        <template v-slot:[`item.cpy_name`]="{ item }">
            <strong flat color="blue" class="ma-0 subheading text-uppercase blue--text">{{ item.cpy_name }}</strong>
        </template>
        <template v-slot:[`item.cpy_sta_id`]="{ item }">
            <!-- <v-chip small outlined label :color="stateListById[item.cpy_sta_id].sta_color">{{ stateListById[item.cpy_sta_id].sta_label }}</v-chip>-->
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" small outlined label :color="stateListById[item.cpy_sta_id].sta_color">
                        {{ stateListById[item.cpy_sta_id].sta_label }}
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(itemState, index) in stateListByGroup['GEN']" :key="index" link>
                        <v-list-item-title @click="updateCompanyState(item, itemState.sta_id)">{{ itemState.sta_label }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
        
        <template v-slot:[`item.cpy_created_at`]="{ item }">
            <v-chip label outlined>{{ $date(item.cpy_created_at).format('DD/MM/YYYY') }}</v-chip>
        </template>
        <template v-slot:[`item.quo_created_at_max`]="{ item }">
            <v-chip label outlined :color="lastTaskSince(item.quo_created_at_max)">{{ (item.quo_created_at_max) ? $date(item.quo_created_at_max).format('DD/MM/YYYY') : '---/---/--------' }}</v-chip>
            <v-badge bordered :color="bubbleColor(item.quo_id_count)" overlap :content="(!item.quo_id_count) ? '0' : item.quo_id_count" title="Devis" class="ml-3">
                <v-icon >mdi-invoice-list-outline</v-icon>
            </v-badge>
        </template>
        <template v-slot:[`item.usr_id_count`]="{ item }">
            <v-chip v-bind="attrs" v-on="on" small outlined label :color="typeListById[item.cpy_typ_id].typ_color">
                {{ typeListById[item.cpy_typ_id].typ_name }}
            </v-chip>
            <v-badge bordered :color="bubbleColor(item.usr_id_count)" overlap :content="(!item.usr_id_count) ? '0' : item.usr_id_count" title="Utilisateur" class="ml-3">
                <v-icon >mdi-account</v-icon>
            </v-badge>
        </template>
        <template v-slot:[`item.cpy_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/companies/' + item.cpy_id + ''">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Ouvrir
            </v-btn>
        </template>
        <!--         <template v-slot:[`item.cpy_billed_at`]="{ item }">
            <v-chip label outlined>{{ (item.cpy_billed_at) ? $date(item.cpy_billed_at).format('DD/MM/YYYY') : '---/---/--------' }}</v-chip>
        </template> -->
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'

import { useCompanyStore } from '@/stores/company'

export default {
    name: 'companies_edit',
    props: [ ],
    mixins: [ ],
    computed: {
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
    },
    data: function () {
        return {
            loading: { getgetTaskItems: false },
            validFrom: true,
            items        : [],
            taskSelected : {},
            selectFields : {},
            actionTask   : [],
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Nom',            align: 'left', value: 'cpy_name'},
                { text: 'Statut',         align: 'left', value: 'cpy_sta_id'},
                { text: 'Info',           align: 'center', value: 'usr_id_count'},
                { text: 'Dern. devis le', align: 'center', value: 'quo_created_at_max'},
                { text: 'Création',       align: 'left',   value: 'cpy_created_at'},
                //{ text: 'Facture',        align: 'left',   value: 'cpy_billed_at'},
                { text: 'Action',         align: 'right',  value: 'cpy_id'},
            ],
        }
    },
    beforeMount: function(){
        this.getItems()
    },
    mounted: function (){

    },
    methods: {
        ...mapActions(useCompanyStore, {updateItem: 'updateItem'}),
        getItems(){
            this.$http.get('/companies/?per_page=false&extended=viewall,withcountuser,withcounttask').then( (response) => {
                this.items = response.data.data
            })
        },
        bubbleColor( userCount ){
            if( !userCount ){
                return 'error'
            } else if( userCount == 1 ) {
                return 'blue'
            } else {
                return 'green'
            }
        },
        lastTaskSince(quo_date_create){
            let taskDate = this.$date(quo_date_create)
            let today    = this.$date()
            let nbDay = today.diff(taskDate, 'day')
            if( !quo_date_create ){
                return 'grey'
            }
            if( !nbDay ){
                return 'green'
            } else if( nbDay > 60 ) {
                return 'error'
            } else {
                return 'blue'
            }
        },
        async updateCompanyState(item, sta_id){
            item.cpy_sta_id = sta_id
            await this.updateItem(item)
            this.getItems
        }
    },
}
</script>